import { useState, useEffect} from 'react'

const useIntersection = (ref, rootMargin) => {
    const [isVisible, setState] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                entry.isIntersecting && setState(true)
            }, { rootMargin }
        );
        ref.current && observer.observe(ref.current)
    }, [ref, rootMargin])

    return isVisible
}

export default useIntersection

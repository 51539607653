import './projects.css'
import decktradeVideo from '../../assets/decktrade-vid.mp4'
import lastFiveVideo from '../../assets/lastfive-vid.mp4'
import { ReactComponent as ReactLogo } from '../../assets/react.svg'
import { ReactComponent as TypescriptLogo } from '../../assets/typescript.svg'
import { ReactComponent as NodeJsLogo } from '../../assets/nodejs.svg'
import { ReactComponent as PythonLogo } from '../../assets/python.svg'
import { ReactComponent as GithubUrl } from '../../assets/github-url.svg'
import { ReactComponent as HerokuLogo } from '../../assets/heroku.svg'
import { ReactComponent as Browse } from '../../assets/browse.svg'
import { ReactComponent as AWSLogo } from '../../assets/aws.svg'

import { useRef } from 'react'
import useIntersection from '../intersection/intersection'

const Projects = () => {

    const project = useRef(null)

    const titleInViewport = useIntersection(project, '0px')

    return (
        <div className='projects'>
            <div className='project-header' ref={project}>
                <div className={`project-headline ${titleInViewport ? 'shown' : 'hidden'}`}>
                    Projects
                </div>
            </div>
            <div className='project-list'>
                <div className={`project ${titleInViewport ? 'shown' : 'hidden'}`}>
                    <div className='project-info'>
                        <div className='project-title'>
                            DeckTrade.io
                        </div>
                        <div className='project-description'>
                            Decktrade is an online platform that allows users to collect and trade trading cards. Decktrade is built with React.js/Typescript on the Front End, along with Node.js and entirely hosted and configured with AWS services on the backend.  
                        </div>
                        <div className='project-technologies'>
                            <ReactLogo/>
                            <TypescriptLogo/>
                            <NodeJsLogo/>
                            <AWSLogo/>
                        </div>
                        <div className='project-links'>
                            <a className='project-button' href='https://github.com/Miszion/deck-trade'>
                                <div className='project-button-title'>
                                    Check out on GitHub
                                </div>
                                <div className='project-button-image'>
                                    <GithubUrl/>
                                </div>
                            </a>
                            <a className='project-button' href='https://decktrade.io/'>
                                <div className='project-button-title'>
                                    Check out live project
                                </div>
                                <div className='project-button-image'>
                                    <Browse/>
                                </div>
                            </a>
                        </div>
                    </div>
                    <video className='project-video' muted autoPlay loop>
                        <source src={decktradeVideo} type='video/mp4'></source>
                    </video>
                </div>
                <div className={`project-reverse ${titleInViewport ? 'shown' : 'hidden'}`}>
                    <video className='project-video' muted autoPlay loop>
                        <source src={lastFiveVideo}type='video/mp4'></source>
                    </video>
                    <div className='project-info'>
                        <div className='project-title'>
                            Last Five
                        </div>
                        <div className='project-description'>
                            Last Five is an online statistics generator for the popular MOBA game League of Legends. This tool calculates statistics based on the player's last five games played, by analyzing Riot Games' API. It also calculates rank, most played champion, win rate, damage dealt to champions, and overall gold earned. Last Five was built with React.js on the front end, with Python on the backend hosted on Heroku. 
                        </div>
                        <div className='project-technologies'>
                            <ReactLogo/>
                            <PythonLogo/>
                            <HerokuLogo/>
                        </div>
                        <div className='project-links'>
                            <a className='project-button' href='https://github.com/Miszion/last-five'>
                                <div className='project-button-title'>
                                    Check out on GitHub
                                </div>
                                <div className='project-button-image'>
                                    <GithubUrl/>
                                </div>
                            </a>
                            <a className='project-button' href='https://last-five.herokuapp.com/'>
                                <div className='project-button-title'>
                                    Check out live project
                                </div>
                                <div className='project-button-image'>
                                    <Browse/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects
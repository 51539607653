import React from 'react'
import resumePDF from '../../assets/mission-resume.pdf'
import './header.css'
import { ReactComponent as Resume } from '../../assets/resume.svg'
import { ReactComponent as Github } from '../../assets/github.svg'
import { ReactComponent as LinkedIn} from '../../assets/linked.svg'

const Header = () => {
    return (
        <div className='header-container'>
            <div className='header'>
                <div className="links">
                    <div className="resume"> 
                        <a href={resumePDF} download>
                            <Resume/>
                        </a> 
                    </div>
                    <div className="github">
                        <a href="https://github.com/miszion" label='GitHub'>
                            <Github/>
                        </a>
                    </div>
                    <div className="linked">
                        <a href="https://www.linkedin.com/in/mission-marcus/">
                            <LinkedIn/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header
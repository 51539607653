import './footer.css'
const Footer = () => {
    return (
        <footer>
            <div className='footer-container'>
                <div className='copyright'>
                    ©
                </div>
                <div className='reserved'>
                    {new Date().getFullYear()} Mission Marcus <br></br>
                    All Rights Reserved
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React, { useRef} from 'react'
import './bio.css'
import Email from '../../assets/email.png'
import useIntersection from '../intersection/intersection'

const Bio = () => {
    const ref = useRef(null)

    const inViewport = useIntersection(ref, '-200px')

    return(
        <div className='bio-block' ref={ref}>
            <div className={`bio-section ${inViewport ? 'shown' : 'hidden'}`}>
                <div className='about'>Hi, there!</div>
                <div className='bio'>I am Mission Marcus, a Software Engineer from Chicago, Illinois. I obtained my Bachelor's of Science in Computer Science, with a minor in Mathematics from the University of Illinois at Chicago. I am currently working as a Software Engineer II at Amazon's Mechatronics and Sustainable Packaging Organization. My favorite research topics include software optimization, data structures, and program design. I am AWS certified as a Developer and a Solutions Architect.</div>
                <div className='social-section'>
                    <a className='social-button' href='mailto:missionmarcus@gmail.com'>
                        <div className='social-button-text'>Send me an email</div>
                        <img className='social-button-image' src={Email} alt='Send me an email'></img>
                    </a>
                </div>
            </div>
        </div>
    )

}

export default Bio
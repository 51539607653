import React from 'react';
import './stylesheet.css';
import Header from './components/header/header';
import Bio from './components/bio-section/bio';
import ImageBlock from './components/image-block/ImageBlock'
import Projects from './components/projects/projects'
import Footer from './components/footer/footer';

const App = () => {
    return (
      <div className='app-container'>
        <Header/>
        <ImageBlock/>
        <Bio/>
        <Projects/>
        <Footer/>
      </div>
    );
}


export default App;

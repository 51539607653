import { React, useRef} from "react";
import MissionCoda from '../../assets/picture3.jpg'
import DevAssociate from '../../assets/dev-associate.png'
import SolutionsArchitect from '../../assets/solutions-architect.png'
import useIntersection from "../intersection/intersection";
import './imageblock.css'

const ImageBlock = () => {
  
    const ref = useRef(null)

    const inViewport = useIntersection(ref, '0px')

    return(
        <div className='picture-block' ref={ref}>
            <div className={`picture ${inViewport ? 'shown' : 'hidden'}`}>
                <div className='shadow-circle'></div>
                <img className='bio-pic' src={MissionCoda} alt="Mission and his brother"></img>
                    <div className='name-div'>
                        <div className='title'>FULL STACK SOFTWARE ENGINEER</div>
                        <div className='name-section'>Mission Marcus</div>
                        <div className='certifications'>
                            <img class='certification' src={DevAssociate} alt='AWS Certified Developer - Associate'/>
                            <img class='certification' src={SolutionsArchitect} alt='AWS Certified Solutions Architect - Associate'/>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default ImageBlock;